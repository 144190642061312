<template>
    <div class="page-story-detail">
        <div class="container">
            <div class="breadcrumb">
                <router-link to="/">Home</router-link>
                <right theme="filled" size="18" fill="#ccc" />
                <router-link :to="`/${category.type}/${category.id}`">{{ category.title }}</router-link>
                <right theme="filled" size="18" fill="#ccc" />
                <span>Detail</span>
            </div>
            <div class="page-container">
                <div class="head">
                    <h2>{{ detail.title }}</h2>
                    <div class="date">
                        <calendar-dot class="icon" theme="filled" size="12" fill="#999" :strokeWidth="3"/>
                        <span class="text">{{ detail.published_at }}</span>
                        <eyes class="icon" theme="filled" size="16" fill="#999" :strokeWidth="3"/>
                        <span class="text">{{ detail.visits }}</span>
                    </div>
                </div>
                <div class="detail">
                    <div v-html="detail.content.content"></div>
                </div>
            </div>
            <div class="pre-next">
                <div class="item">
                    <span>Previous</span>
                    <template v-if="detail.previous">
                        <router-link class="a" :to="`/article/detail/${detail.previous.id}`">
                            {{ detail.previous.title }}
                        </router-link>
                    </template>
                    <span class="grey" v-else>No More</span>
                </div>
                <div class="item">
                    <span>Next</span>
                    <template v-if="detail.next">
                        <router-link class="a" :to="`/article/detail/${detail.next.id}`">
                            {{ detail.next.title }}
                        </router-link>
                    </template>
                    <span class="grey" v-else>No More</span>
                </div>
            </div>
        </div>
    </div>
    <div class="page-bg">
        <img class="bg" src="@/assets/images/product-banner.png" />
    </div>
</template>

<script>
import { Right, CalendarDot, Eyes } from '@icon-park/vue-next'
export default {
    name: 'PageStoryDetail',
    components: {
        Right, CalendarDot, Eyes
    },
    data(){
        return {
            detail: {
                content: { 
                    content: ''
                },
                previous: {
                    id: 0,
                    title: ''
                },
                next: {
                    id: 0,
                    title: ''
                }
            },
            category: {}
        }
    },
    watch:{
        $route(){
            this.getDetail(this.$route.params.id);
        }
    },
    created(){
        document.oncontextmenu = new Function("event.returnValue=false");
        document.onselectstart = new Function("event.returnValue=false");
    },
    mounted(){
        this.getDetail(this.$route.params.id);
    },
    unmounted(){
        document.oncontextmenu = new Function("event.returnValue=true");
        document.onselectstart = new Function("event.returnValue=true");
    },
    methods:{
        getDetail(id){
            this.$api.showArticle(id).then(res => {
                this.detail = res.data;
                this.getCategory(res.data.cat_id);
            })
        },
        getCategory(cat_id){
            this.$api.category(cat_id).then(res => {
                this.category = res.data;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-story-detail{
    z-index: 99;
    position: relative;
    .container{
        width: 1200px;
        margin: 0 auto;
    }
    .breadcrumb{
        padding: 120px 0 10px;
    }
    .page-container{
        @extend .flex-column;
        padding: 20px;
        border-radius: $radius-big;
        background-color: $white;
        box-shadow: $shadow-default;
        .head{
            @extend .flex-column;
            border-bottom: 1px solid #eee;
            h2{
                font-family: 'cusTitle';
                font-size: 24px;
                color: $orange;
            }
            .date{
                @extend .flex-row;
                margin: 20px 0;
                font-size: 14px;
                color: $info;
                .icon{
                    @extend .flex-column-middle;
                }
                .text{
                    margin: 0 15px 0 3px;
                }
            }
        }
        .detail{
            margin-top: 20px;
        }
    }
    .pre-next{
        @extend .flex-row-between;
        margin: 30px 0;
        border-radius: $radius-big;
        background-color: $white;
        box-shadow: $shadow-default;
        .item{
            @extend .flex-column;
            width: 50%;
            padding: 20px;
            span{
                margin-bottom: 10px;
                font-size: 12px;
                color: $info;
            }
            .a{
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: 'cusTitle';
                font-size: 22px;
                color: $text-color;
                &:hover{
                    color: $orange;
                }
            }
            .grey{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: 'cusTitle';
                font-size: 22px;
                color: $info;
            }
        }
    }
}
</style>